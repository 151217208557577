import { useEffect, useState, useRef } from "react";
import { Stage, Layer, Text, Circle, Line, Group, Rect } from "react-konva";
import IconButton from "@mui/material/IconButton";
import ReplayIcon from "@mui/icons-material/Replay";
import Slider from "@mui/material/Slider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import BackspaceIcon from "@mui/icons-material/Backspace";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";

import {
  getEdges,
  inializeNodes,
  updateNodes,
  xBound,
  yBound,
  dataUrItoBlob,
  getAttribution,
} from "../common";

import useSound from "use-sound";
import clickSfx from "./media/click.mp3";
import victorySfx from "./media/victory.mp3";

import "./index.css";

const App = ({
  word,
  clue,
  canvas,
  hideClue,
  submittedBy,
  submittedLocation,
  userImage,
  scale,
  onSuccess,
  onLayoutComplete,
  isPreview,
}) => {
  const [nodes, setNodes] = useState(inializeNodes(word, canvas));
  const [SL, setSL] = useState(25);

  // Constants related to the layout algorithm
  const ITERATIONS = 150;
  var scale = scale || 1.0;

  // Controls for animation

  const [dragModeRefresh, setDragModeRefresh] = useState(0); // Force rerender when dragging
  const [frameCount, setFrameCount] = useState(0);
  const [reloadButton, setReloadButton] = useState(0.0); // Used to force graph to rerender

  const [userSolution, setUserSolution] = useState("");
  const stageRef = useRef(null);

  // If they
  onLayoutComplete = onLayoutComplete || (() => {});

  // See https://github.com/joshwcomeau/use-sound for docs
  const [playClick] = useSound(clickSfx);
  const [playVictory] = useSound(victorySfx);

  const INSTRUCTIONS = "* Drag to arrange\n* Click to solve\n* Spell solution";

  // Initialize the graph
  useEffect(() => {
    setNodes(inializeNodes(word, canvas));
    setUserSolution("");
    setDragModeRefresh(Math.random());
  }, [word]);

  // Perform animation calculations when frameCount advances
  useEffect(() => {
    try {
      var newNodes = updateNodes(nodes, edges, canvas, SL);
      setNodes(newNodes);
    } catch (e) {
      console.log(e);
    }
  }, [frameCount]);

  // Set up animation on changes of word, spring length, dragging or reload
  var N = 0;
  var animating = false;
  var attempts = 0;

  useEffect(() => {
    const animate = async () => {
      if (N < ITERATIONS && animating) {
        window.requestAnimationFrame(animate);
        N = N + 1;
        setFrameCount(N);
      }
      if (N == ITERATIONS) {
        N = 0;
        animating = false;
      }
    };
    animating = true;
    attempts = 0;
    animate();
  }, [SL, dragModeRefresh, reloadButton]);

  // Detect when animation is complete and pass image blob back up to parent
  useEffect(() => {
    if (frameCount == ITERATIONS) {
      try {
        var url = stageRef.current.toDataURL();
        onLayoutComplete(dataUrItoBlob(url));
      } catch (e) {
        console.log(e);
      }
    }
  }, [frameCount]);

  // Detect when user has solved the puzzle
  useEffect(() => {
    if (word.length > 0) {
      if (userSolution.toUpperCase() == word.toUpperCase()) {
        playVictory();
        onSuccess();
      }
    }
  }, [userSolution]);

  function handleDrag(k, e) {
    var newNodes = nodes;
    newNodes[k] = {
      x: xBound(e.target.attrs.x, canvas),
      y: yBound(e.target.attrs.y, canvas),
    };
    setNodes(newNodes);
    setDragModeRefresh(Math.random());
  }

  function handleUserSolution(k) {
    setUserSolution(userSolution + k);
    playClick();
  }

  const userImgStyle = {
    borderRadius: "50%",
    marginLeft: "12px",
    verticalAlign: "middle",
  };

  var edges = getEdges(word);

  return (
    <Grid container>
      <Grid item xs={12}>
        {hideClue ? null : (
          <div className="clue">
            <Typography variant="h4">
              <i>{clue}</i>
            </Typography>
          </div>
        )}
      </Grid>

      <Grid item xs={12}>
        <ArrowForwardIosIcon className="arrow" />

        <span className="solution">
          <Typography variant="h5" display="inline">
            {userSolution}
            <span className="solution blink">
              <b> __ </b>
            </span>
          </Typography>
        </span>

        {userSolution.length > 0 ? (
          <BackspaceIcon
            className="grow"
            onClick={() => {
              // This is the backspace button, so clip a char off the end
              setUserSolution(userSolution.slice(0, -1));
              playClick();
            }}
            onTap={() => {
              // This is the backspace button, so clip a char off the end
              setUserSolution(userSolution.slice(0, -1));
              playClick();
            }}
          />
        ) : null}
      </Grid>

      <Grid item xs={12}>
        <center>
          <Stage
            scale={{ x: scale, y: scale }}
            width={canvas.W * scale}
            height={canvas.H * scale}
            style={{ background: "#FFFFFF", touchAction: "none" }}
            ref={stageRef}
          >
            <Layer>
              {isPreview ? (
                <Rect
                  x={5}
                  y={5}
                  width={canvas.W - 5}
                  height={canvas.H - 5}
                  fill="#FFFFFF"
                  stroke="lightgrey"
                  strokeWidth={2}
                  cornerRadius={10}
                />
              ) : (
                <Rect width={canvas.W} height={canvas.H} fill="#FFFFFF" />
              )}
              <Text
                text={INSTRUCTIONS}
                fontSize={canvas.W / 20}
                fontFamily="Roboto"
                fill="lightgrey"
                x={20}
                y={20}
                width={canvas.W}
                height={canvas.H}
              />
              {edges.map((e, i) => {
                var N = Object.keys(nodes);
                if (N.includes(e[0]) && N.includes(e[1])) {
                  var points = [0, 0, 0, 0];
                  if (Object.keys(nodes).length > 0) {
                    points = [
                      nodes[e[0]].x,
                      nodes[e[0]].y,
                      nodes[e[1]].x,
                      nodes[e[1]].y,
                    ];
                  }
                  return (
                    <Line
                      shadowBlur={3}
                      key={"edge-" + i}
                      points={points}
                      stroke="black"
                    />
                  );
                }
              })}
            </Layer>
            <Layer>
              {Object.keys(nodes).map((k, idx) => {
                return (
                  <Group key={"node-group" + k + "-" + idx}>
                    <Circle
                      x={nodes[k].x}
                      y={nodes[k].y}
                      stroke="black"
                      fill="white"
                      shadowBlur={3}
                      radius={canvas.R}
                    />
                    <Text text={k} x={nodes[k].x - 3} y={nodes[k].y - 3} />
                    <Circle
                      draggable
                      x={nodes[k].x}
                      y={nodes[k].y}
                      radius={canvas.R}
                      opacity={0}
                      onDragMove={(e) => {
                        e.target.attrs.x = xBound(e.target.attrs.x, canvas);
                        e.target.attrs.y = yBound(e.target.attrs.y, canvas);
                        handleDrag(k, e);
                      }}
                      onMouseUp={() => {
                        handleUserSolution(k);
                      }}
                      onTap={() => {
                        handleUserSolution(k);
                      }}
                    />
                  </Group>
                );
              })}
            </Layer>
          </Stage>
        </center>
      </Grid>

      <Grid item xs={12}>
        <Stack spacing={2} direction="row">
          <Slider
            min={20}
            max={100}
            value={SL}
            onChange={(e, v) => {
              setSL(v);
            }}
          />

          <IconButton
            color="primary"
            size="small"
            onClick={() => {
              setNodes(inializeNodes(word, canvas));
              setReloadButton(Math.random());
              setUserSolution("");
            }}
          >
            <ReplayIcon />
          </IconButton>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <div className="credits">
          {hideClue ? (
            <div style={{ height: "12px" }}>
              {frameCount < ITERATIONS ? (
                <LinearProgress
                  variant="determinate"
                  value={(frameCount / ITERATIONS) * 100}
                />
              ) : null}
            </div>
          ) : (
            <center>
              <Typography>
                <i>{getAttribution(submittedBy, submittedLocation)}</i>
              </Typography>
            </center>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default App;
