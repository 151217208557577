import { useEffect, useState, useContext } from "react";
import Chrome from "../Chrome";

import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";

import { Redirect } from "react-router-dom";

import { AuthContext } from "../../auth_context";
import { db } from "../../firebase";

import "./index.css";

const textInputSX = {
  marginRight: "12px",
};

const App = (props) => {
  const [profile, setProfile] = useState({
    userName: "",
    userLocation: "",
    newsletterSubscriber: false,
  });

  const authContext = useContext(AuthContext);

  // Get the user's profile data
  useEffect(() => {
    if (authContext.user) {
      db.collection("profiles")
        .where("uid", "==", authContext.user.uid)
        .limit(1)
        .get()
        .then((doc) => {
          doc.forEach((doc) => {
            setProfile(doc.data());
          });
        });
    }
  }, [authContext]);

  // handle changes for the user profile
  const handleProfileUpdate = (p) => {
    db.collection("profiles")
      .where("uid", "==", authContext.user.uid)
      .limit(1)
      .get()
      .then((doc) => {
        if (doc.empty) {
          db.collection("profiles").add({
            uid: authContext.user.uid,
            ...p,
          });
        } else {
          doc.forEach((doc) => {
            doc.ref.update({
              ...p,
            });
          });
        }
      });
  };

  if (!authContext.user) {
    return;
  }

  return (
    <div className="profileContent">
      <TextField
        sx={textInputSX}
        variant="outlined"
        label="Profile Name"
        value={profile.userName}
        onChange={(e) => {
          const p = {
            ...profile,
            userName: e.target.value,
          };
          setProfile(p);
          handleProfileUpdate(p);
        }}
      />
      <TextField
        sx={textInputSX}
        variant="outlined"
        label="Location"
        value={profile.userLocation}
        onChange={(e) => {
          const p = {
            ...profile,
            userLocation: e.target.value,
          };
          handleProfileUpdate(p);
          setProfile(p);
        }}
      />
      <br />
      <Switch
        checked={profile.newsletterSubscriber}
        onChange={(e) => {
          const p = {
            ...profile,
            newsletterSubscriber: !profile.newsletterSubscriber,
          };
          handleProfileUpdate(p);
          setProfile(p);
        }}
      />{" "}
      Subscribe to daily email
    </div>
  );
};

export default App;
