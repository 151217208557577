import * as React from "react";
import { useContext, useEffect, useState } from "react";

import gravatarUrl from "gravatar-url";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import LoginIcon from "@mui/icons-material/Login";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";

import { Link } from "react-router-dom";
import { Dialog } from "@mui/material";
import { DialogTitle } from "@mui/material";

import { AuthContext } from "../../auth_context";

import "./index.css";

const Main = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const authContext = useContext(AuthContext);

  const contentDivSX = {
    margin: "24px",
  };

  const userImgStyle = {
    borderRadius: "50%",
    margin: "6px",
  };

  var userImg = "";
  if (authContext.user) {
    userImg = gravatarUrl(authContext.user.email, { size: 36 });
  }

  useEffect(() => {
    if (authContext.user) {
      authContext.setAuthDialogOpen(false);
    }
  }, [authContext.user]);

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleProfileClose = () => {
    setAnchorEl(null);
  };

  const SideNav = () => {
    return (
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleProfileClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={() => {
            authContext.logout();
            handleProfileClose();
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    );
  };

  const PublishLink = () => {
    if (authContext.user) {
      if (authContext.user.email == "andrew@odewahn.com") {
        return (
          <React.Fragment>
            <Link to="/schedule" className="headerNav">
              <Typography>Schedule</Typography>
            </Link>
            <Link to="/review" className="headerNav">
              <Typography>Review</Typography>
            </Link>
          </React.Fragment>
        );
      }
    }
  };

  // See https://stackoverflow.com/questions/72440972/how-to-push-the-footer-in-mui-at-the-very-bottom-and-make-it-stick
  // https://dev.to/gedalyakrycer/ohsnap-this-how-to-pin-footers-in-react-3k1k
  function Footer() {
    return (
      <Paper component="footer" square>
        <Container maxWidth="lg">
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "center",
              display: "flex",
              mb: 2,
            }}
          >
            <Typography variant="caption" color="gray">
              Copyright ©2022. Andrew Odewahn. All rights reserved.
            </Typography>
          </Box>
        </Container>
      </Paper>
    );
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Dialog
        open={authContext.authDialogOpen}
        fullWidth={true}
        onClose={() => authContext.setAuthDialogOpen(false)}
      >
        <DialogTitle>Sign In</DialogTitle>
        <div style={{ margin: "24px" }}>
          You must be signed in to create and submit puzzles.
        </div>
        <authContext.SignIn />
      </Dialog>

      <div className="content-container">
        <AppBar position="static">
          <Toolbar>
            <SideNav />

            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <Link className="homeLink" to="/">
                dfsWord
              </Link>
            </Typography>

            <PublishLink />

            <Link className="headerNav" to="/create">
              <Typography>Submit</Typography>
            </Link>

            <Link className="headerNav" to="/playground">
              <Typography>Playground</Typography>
            </Link>

            <Link className="headerNav" to="/about">
              <Typography>About</Typography>
            </Link>

            {userImg.length > 0 ? (
              <div id="profile-icon" onClick={handleProfileClick}>
                <img style={userImgStyle} src={userImg} />
              </div>
            ) : (
              <LoginIcon
                onClick={() => {
                  authContext.setAuthDialogOpen(true);
                }}
              />
            )}
          </Toolbar>
        </AppBar>
        <div style={contentDivSX}>{props.children}</div>
      </div>
      <div className="footer-container">
        <Footer />
      </div>
    </Box>
  );
};

export default Main;
