import { useEffect, useState } from "react";

import Typography from "@mui/material/Typography";

import { TEXT } from "./about";

import Chrome from "../Chrome";

const Main = (props) => {
  return (
    <Chrome>
      <div className="content">
        <Typography>
          <div dangerouslySetInnerHTML={{ __html: TEXT }} />
        </Typography>
      </div>
    </Chrome>
  );
};
export default Main;
