import firebase from "firebase/compat/app";
import firestore from "firebase/compat/firestore";
import auth from "firebase/compat/auth";
import {
  getStorage,
  ref,
  uploadString,
  uploadBytes,
  connectStorageEmulator,
  getDownloadURL,
} from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD_2Cwx543LW05WxLN_ILpTiR8v0QYpwZo",
  authDomain: "bfsword-369021.firebaseapp.com",
  projectId: "bfsword-369021",
  storageBucket: "bfsword-369021.appspot.com",
  messagingSenderId: "307501524532",
  appId: "1:307501524532:web:49288cd6b93e2692038c61",
};

const app = firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const firebaseAuth = firebase.auth();
const storage = getStorage();

if (window.location.hostname === "localhost") {
  console.log("Using emulator");
  db.useEmulator("localhost", 8080); // Adding http makes this break
  connectStorageEmulator(storage, "localhost", 9199);
  firebaseAuth.useEmulator("http://localhost:9099");
  //firebase.functions().useEmulator("localhost", 5001);
  //functions.useEmulator("localhost", 5001);
}

export {
  db,
  firebaseAuth,
  storage,
  ref,
  uploadString,
  uploadBytes,
  getDownloadURL,
};
