import React, { useEffect, useState, useContext } from "react";
import Chrome from "../Chrome";

import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";

import { Redirect } from "react-router-dom";

import { AuthContext } from "../../auth_context";
import { db } from "../../firebase";

const App = (props) => {
  const [unscheduled, setUnScheduled] = useState([]);
  const [scheduled, setScheduled] = useState([]);

  const authContext = useContext(AuthContext);

  // Set up the snapshot listener
  useEffect(() => {
    db.collection("published")
      .where("publicationDate", "!=", "")
      .orderBy("publicationDate", "desc")
      .limit(50)
      .onSnapshot((snapshot) => {
        setScheduled(snapshot.docs.map((doc) => doc.data()));
      });
  }, []);

  // Set up the snapshot listener
  useEffect(() => {
    db.collection("published")
      .where("publicationDate", "==", "")
      .onSnapshot((snapshot) => {
        setUnScheduled(snapshot.docs.map((doc) => doc.data()));
      });
  }, []);

  const updatePublishedField = (item, field, label) => {
    return (
      <TextField
        InputLabelProps={{ shrink: true }}
        label={label}
        defaultValue={item[field] || ""}
        onKeyDown={async (e) => {
          if (e.key === "Tab") {
            var newVal = item;
            newVal[field] = e.target.value;
            await db
              .collection("published")
              .doc(item.publicationId)
              .set(newVal);
          }
        }}
      />
    );
  };

  const listPublished = (item) => {
    return (
      <Stack direction="row" spacing={2}>
        {updatePublishedField(item, "publicationDate", "Publication Date")}
        {updatePublishedField(item, "word", "Word")}
        {updatePublishedField(item, "clue", "Clue")}
        {updatePublishedField(item, "submittedBy", "Submitted By")}
        {updatePublishedField(item, "submittedLocation", "Submitted Location")}
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={async () => {
            await db.collection("published").doc(item.publicationId).delete();
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Stack>
    );
  };

  // You always have to be logged in to see this page
  if (authContext.user) {
    if (authContext.user.email !== "andrew@odewahn.com") {
      return <Redirect to="/" />;
    }
  }

  return (
    <Chrome>
      <h2>Not Scheduled</h2>
      {unscheduled.map((item) => {
        return (
          <React.Fragment key={"unscheduled-" + item.publicationId}>
            {listPublished(item)}
            <div style={{ height: "1em" }} />
          </React.Fragment>
        );
      })}
      <h2>Scheduled</h2>
      {scheduled.map((item) => {
        return (
          <React.Fragment key={"scheduled-" + item.publicationId}>
            {listPublished(item)}
            <div style={{ height: "1em" }} />
          </React.Fragment>
        );
      })}
    </Chrome>
  );
};

export default App;
