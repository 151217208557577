import { React, useEffect, useState } from "react";
import { createContext } from "react";

import firebase from "firebase/compat/app";

import { firebaseAuth } from "./firebase";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { useHistory } from "react-router-dom";

const AuthContext = createContext();

const AuthProvider = (props) => {
  const [user, setUser] = useState(null);
  const [authDialogOpen, setAuthDialogOpen] = useState(false);

  const history = useHistory();

  // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // Display Google
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
    callbacks: {
      signInSuccessWithAuthResult: (user) => {
        console.log(user.user);
      },
    },
  };

  // Setup listener for the user
  useEffect(() => {
    firebaseAuth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
        setAuthDialogOpen(false);
        history.push("/");
      }
    });
  }, []);

  const SignIn = () => {
    return (
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebaseAuth} />
    );
  };

  const logout = async () => {
    try {
      await firebaseAuth.signOut();
      setUser(null);
    } catch (error) {
      //add error handling soon
      console.log("An error occurred", error);
    }
  };

  return (
    <AuthContext.Provider
      value={{ user, SignIn, logout, authDialogOpen, setAuthDialogOpen }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
