import { useEffect, useState, useContext } from "react";

import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import ShareIcon from "@mui/icons-material/Share";
import Stack from "@mui/material/Stack";
import LoginIcon from "@mui/icons-material/Login";

import { CopyToClipboard } from "react-copy-to-clipboard";

import Chrome from "../Chrome";
import LayoutAndSolve from "../LayoutAndSolve";
import { db, storage, ref, getDownloadURL } from "../../firebase";
import AnonymousSuggestion from "../AnonymousSuggestion";

import { AuthContext } from "../../auth_context";

import { TEXT } from "./text";
import "./index.css";

const Main = (props) => {
  const [puzzle, setPuzzle] = useState(null);
  const [puzzles, setPuzzles] = useState([]);
  const [index, setIndex] = useState(-1);

  const authContext = useContext(AuthContext);

  const today = () => {
    let d = new Date();
    return d.toISOString().split("T")[0];
  };

  // Set up the snapshot listener
  useEffect(() => {
    db.collection("published")
      .where("publicationDate", "<=", today())
      .orderBy("publicationDate", "desc")
      .limit(50)
      .onSnapshot((snapshot) => {
        setPuzzles(snapshot.docs.map((doc) => doc.data()));
        if (snapshot.docs.length > 0) {
          if (!props.match.params.publicationId) {
            setIndex(0);
          }
        }
      });
  }, []);

  // Set the puzzle when the index changes
  useEffect(() => {
    if (index > -1) {
      setPuzzle(puzzles[index]);
    }
  }, [index]);

  // If there is a publicationId in the URL, then fetch a single puzzle
  useEffect(() => {
    if (props.match.params.publicationId) {
      try {
        db.collection("published")
          .doc(props.match.params.publicationId)
          .get()
          .then((doc) => {
            const p = doc.data();
            setPuzzle(p);
          });
      } catch (e) {
        if (puzzles.length > 0) {
          setPuzzle(0);
        }
      }
    }
  }, [props.match.params.publicationId]);

  const LoginPrompt = () => {
    return (
      <div className="loginPromptContent">
        <Stack direction="row" spacing={6}>
          <Typography>
            Login with Google to subscribe to the daily email or submit puzzle
            ideas.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              authContext.setAuthDialogOpen(true);
            }}
          >
            <LoginIcon style={{ marginRight: "6px" }} /> Login
          </Button>
        </Stack>
      </div>
    );
  };

  return (
    <Chrome>
      <Grid container spacing={12}>
        <Grid item xs={12} md={6}>
          {puzzle ? (
            <LayoutAndSolve
              word={puzzle.word}
              clue={puzzle.clue}
              submittedBy={puzzle.submittedBy}
              submittedLocation={puzzle.submittedLocation}
              userImage={puzzles.userImage}
              scale={1.0}
              canvas={{ W: 350, H: 300, R: 15 }}
              onSuccess={() => {
                if (index < puzzles.length - 1) {
                  setTimeout(() => {
                    setIndex(index + 1);
                  }, 1000);
                }
              }}
            />
          ) : null}
          <div className="controls">
            <Button
              disabled={index == 0 ? true : false}
              onClick={() => setIndex(index - 1)}
            >
              Previous
            </Button>
            <Button
              disabled={index == puzzles.length - 1 ? true : false}
              onClick={() => setIndex(index + 1)}
            >
              Next
            </Button>
          </div>
          <CopyToClipboard
            text={
              window.location.protocol +
              "//" +
              window.location.host +
              "/puzzles/" +
              puzzle?.publicationId
            }
          >
            <Button>
              <ShareIcon />
              Copy Shareable Link
            </Button>
          </CopyToClipboard>
        </Grid>
        <Grid item xs={12} md={6}>
          <AnonymousSuggestion />
        </Grid>
      </Grid>
    </Chrome>
  );
};
export default Main;
