import { marked } from "marked";

export const TEXT = marked(`
# How to Play

Find the word hidden in the network of letters.

* Rearrange the letters to get a nice layout (i.e., minimize edge crossings, etc).  You can:
    * Drag letters around
    * Use the slider to increase or decrease the length of the edges between letters
    * Press the reload button to get a new initial arrangement.
* Once you have a guess that matches the clue, tap the letters that make up the word.
    * Your solution will appear under the clue
    * Tap the backspace button to remove the last letter
* Got an idea for a puzzle?  Submit your own for consideration.
* Ideas? Comments? Suggestions?  Send 'em to andrew@odewahn.com

`);
