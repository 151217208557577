import React from "react";
import ReactDOM from "react-dom/client";
import { Route, BrowserRouter as Router } from "react-router-dom";

import { createTheme, ThemeProvider, styled } from "@mui/material/styles";

import { AuthProvider } from "./auth_context";

import Main from "./components/Main";
import About from "./components/About";
import Create from "./components/Create";
import Review from "./components/Review";
import Schedule from "./components/Schedule";
import Playground from "./components/Playground";
import Profile from "./components/Profile";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1d3158",
    },
  },
  typography: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Route exact path="/puzzles/:publicationId" component={Main} />
        <Route exact path="/" component={Main} />
        <Route exact path="/playground" component={Playground} />
        <Route exact path="/about" component={About} />
        <Route exact path="/create" component={Create} />
        <Route exact path="/review" component={Review} />
        <Route exact path="/schedule" component={Schedule} />
        <Route exact path="/profile" component={Profile} />
      </AuthProvider>
    </ThemeProvider>
  </Router>
);
