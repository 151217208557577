import { useEffect, useState, useContext } from "react";
import Chrome from "../Chrome";

import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import { Redirect } from "react-router-dom";

import { AuthContext } from "../../auth_context";
import { db, storage, ref, uploadBytes, getDownloadURL } from "../../firebase";
import { getAttribution } from "../common";
import LayoutAndSolve from "../LayoutAndSolve";

const App = (props) => {
  const [submissions, setSubmissions] = useState([]);
  const [submission, setSubmission] = useState({
    word: "",
    clue: "",
    submittedBy: "",
    submittedLocation: "",
    previewImageURL: "",
  });
  const [previewImageBlob, setPreviewImageBlob] = useState(null);

  const [forceRefresh, setForceRefresh] = useState(0.0);

  const authContext = useContext(AuthContext);

  // Set up the snapshot listener
  useEffect(() => {
    db.collection("puzzles")
      .where("status", "==", "submitted")
      .orderBy("createdAt", "desc")
      .onSnapshot((snapshot) => {
        setSubmissions(snapshot.docs.map((doc) => doc.data()));
      });
  }, []);

  const listSubmissions = (item) => {
    return (
      <ListItem
        key={item.puzzleId}
        style={{
          backgroundColor: item.word == submission.word ? "lightgray" : "white",
        }}
        secondaryAction={
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={async () => {
              await db
                .collection("puzzles")
                .doc(item.puzzleId)
                .update({ status: "reviewed" });
            }}
          >
            <DeleteIcon />
          </IconButton>
        }
      >
        <ListItemText
          primary={getAttribution(item.submittedBy, item.submittedLocation)}
          secondary={item.word + " -> " + item.clue}
          onClick={() => {
            setSubmission(item);
            console.log("Clicked", item);
          }}
        />
      </ListItem>
    );
  };

  // You always have to be logged in to see this page
  if (authContext.user) {
    if (authContext.user.email !== "andrew@odewahn.com") {
      return <Redirect to="/" />;
    }
  }

  const updateSubmissionField = (item, field, label) => {
    const textInputSX = {
      marginBottom: "12px",
      width: "100%",
    };
    return (
      <TextField
        sx={textInputSX}
        label={label}
        value={item[field] || ""}
        onChange={(e) => {
          console.log(e.target.value);
          setSubmission({ ...submission, [field]: e.target.value });
        }}
      />
    );
  };

  function uploadPreviewImage(puzzleId, img) {
    const storageRef = ref(storage, puzzleId + ".png");
    // Raw string is the default if no format is provided
    uploadBytes(storageRef, img, {
      contentType: "image/png",
    }).then((snapshot) => {
      setForceRefresh(Math.random());
      getDownloadURL(storageRef).then((url) => {
        setSubmission({ ...submission, previewImageURL: url });
      });
    });
  }

  function requiredFieldsFilled() {
    return submission.word.length > 0 &&
      submission.clue.length > 0 &&
      submission.previewImageURL &&
      submission.previewImageURL.length > 0
      ? false
      : true;
  }

  // Publish the submission to the published collection
  function publishSubmission() {
    console.log("Publishing it", submission);
    var docRef = db.collection("published").doc();
    // Save the submission to the published collection
    docRef.set({
      ...submission,
      publicationId: docRef.id,
      publicationDate: "",
    });
    // Update the status of the submission to accepted
    db.collection("puzzles")
      .doc(submission.puzzleId)
      .update({ status: "accepted" });
  }

  return (
    <Chrome>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <h2>Submitted Posts</h2>
          <List dense={true}>
            {submissions.map((item) => {
              return listSubmissions(item);
            })}
          </List>
        </Grid>
        <Grid item xs={12} md={6}>
          <LayoutAndSolve
            word={submission.word}
            clue={submission.clue}
            scale={1.05}
            canvas={{ W: 300, H: 300, R: 15 }}
            hideClue={true}
            isPreview={true}
            onLayoutComplete={(img) => {
              setPreviewImageBlob(img);
              console.log("Preview image blob", previewImageBlob);
            }}
            onSuccess={() => {
              alert("You solved it!");
            }}
          />
          <Button
            onClick={() => {
              uploadPreviewImage(submission.puzzleId, previewImageBlob);
            }}
          >
            Upload Preview Image
          </Button>

          <h2>Submission Details</h2>

          <Stack direction="row" spacing={2}>
            <div>
              {updateSubmissionField(submission, "word", "Word")}
              {updateSubmissionField(submission, "clue", "Clue")}
              {updateSubmissionField(submission, "submittedBy", "Submitted By")}
              {updateSubmissionField(
                submission,
                "submittedLocation",
                "Submitted Location"
              )}
            </div>
            <div>
              {submission.previewImageURL &&
              submission.previewImageURL.length > 0 ? (
                <img src={submission.previewImageURL + "x=" + forceRefresh} />
              ) : null}
            </div>
          </Stack>
          <Button
            disabled={requiredFieldsFilled()}
            onClick={() => {
              publishSubmission();
            }}
          >
            Accept
          </Button>
        </Grid>
      </Grid>
    </Chrome>
  );
};

export default App;
