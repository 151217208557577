import { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ToggleButton from "@mui/material/ToggleButton";
import Link from "@mui/material/Link";

import { AuthContext } from "../../auth_context";
import { db } from "../../firebase";
import Chrome from "../Chrome";
import LayoutAndSolve from "../LayoutAndSolve";
import Profile from "../Profile";

import { TEXT } from "./guidelines";

import "./index.css";

const textInputSX = {
  marginBottom: "12px",
  width: "100%",
};

const App = (props) => {
  const [word, setWord] = useState("");
  const [clue, setClue] = useState("");
  const [puzzles, setPuzzles] = useState([]);

  const [profile, setProfile] = useState({
    uid: "",
    userName: "",
    userLocation: "",
  });

  const authContext = useContext(AuthContext);
  const history = useHistory();

  // Set up the snapshot listener
  useEffect(() => {
    if (authContext.user) {
      db.collection("puzzles")
        .where("uid", "==", authContext.user.uid)
        .orderBy("createdAt", "desc")
        .limit(50)
        .onSnapshot((snapshot) => {
          setPuzzles(snapshot.docs.map((doc) => doc.data()));
        });
    }
  }, [authContext]);

  // Get the user's profile data
  useEffect(() => {
    if (authContext.user) {
      db.collection("profiles")
        .where("uid", "==", authContext.user.uid)
        .limit(1)
        .onSnapshot((doc) => {
          console.log("got profile");
          doc.forEach((doc) => {
            setProfile(doc.data());
          });
        });
    }
  }, [authContext]);

  useEffect(() => {
    if (puzzles.length > 0) {
      setWord(puzzles[0].word);
      setClue(puzzles[0].clue);
    }
  }, [puzzles]);

  // You always have to be logged in to see this page
  if (authContext.user === null) {
    return (
      <Chrome>
        <div className="loginMessage">
          <Typography variant="h4" component="h1" gutterBottom>
            Create a Puzzle
          </Typography>
          <Typography variant="body1" gutterBottom>
            You must be logged in to create a puzzle. Please log in or create an
            account.
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              authContext.setAuthDialogOpen(true);
            }}
          >
            Log In
          </Button>
        </div>
      </Chrome>
    );
  }

  // handle changes for the user profile

  const listPuzzles = (item) => {
    return (
      <ListItem
        key={item.puzzleId}
        style={{ backgroundColor: item.word == word ? "lightgray" : "white" }}
        secondaryAction={
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={async () => {
              await db.collection("puzzles").doc(item.puzzleId).delete();
            }}
          >
            <DeleteIcon />
          </IconButton>
        }
      >
        <Button
          disabled={item.status !== "private"}
          size="small"
          style={{ marginRight: "12px", width: "100px" }}
          disableRipple
          onClick={async () => {
            await db.collection("puzzles").doc(item.puzzleId).update({
              status: "submitted",
            });
          }}
        >
          Submit
        </Button>

        <ListItemText
          primary={item.clue}
          secondary={item.word}
          onClick={() => {
            setWord(item.word);
            setClue(item.clue);
          }}
        />
        <Typography variant="body2" color="text.secondary">
          {item.status}
        </Typography>
      </ListItem>
    );
  };

  return (
    <Chrome>
      <div className="createContent">
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Profile />
            <TextField
              sx={textInputSX}
              variant="outlined"
              label="Word"
              value={word}
              onChange={(e) => {
                setWord(e.target.value);
              }}
            />
            <TextField
              sx={textInputSX}
              variant="outlined"
              label="Clue"
              value={clue}
              onChange={(e) => {
                setClue(e.target.value);
              }}
            />
            <Button
              disabled={word === "" || clue === "" || authContext.user === null}
              variant="contained"
              onClick={() => {
                // Status can be one of: [private, submitted, accepted, reviewed]
                var docRef = db.collection("puzzles").doc();
                docRef.set({
                  puzzleId: docRef.id,
                  word: word,
                  clue: clue,
                  submittedBy: profile.userName,
                  submittedLocation: profile.userLocation,
                  uid: authContext.user.uid,
                  status: "private",
                  createdAt: Date.now(),
                });
              }}
            >
              Create
            </Button>

            <hr />
            <List dense={true}>
              {puzzles.map((item) => {
                return listPuzzles(item);
              })}
            </List>
          </Grid>
          <Grid item xs={12} md={6}>
            <LayoutAndSolve
              word={word}
              clue={clue}
              scale={1.0}
              canvas={{ W: 300, H: 300, R: 15 }}
              submittedBy={profile.userName}
              submittedLocation={profile.userLocation}
              onSuccess={() => {
                alert("You solved it!");
              }}
            />

            <Typography>
              <span dangerouslySetInnerHTML={{ __html: TEXT }} />
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Chrome>
  );
};

export default App;
