import { marked } from "marked";

export const TEXT = marked(`
# About dfsWord

I accidentally invented dfsWord while developing software to turn complex relationships between data into an easy-to-understand picture. As a quick way to assess the system, I used words, and the relationships between the letters in the word, as test data. This worked particularly well because I could always easily verify whether the system had accurately represented the relationships between the letters by tracing the correct spelling of the word through the diagram.

As my colleagues in technical fields can attest, friends and family often wonder what, exactly, you do all day at the office. In response, I often wound up showing these word diagrams to people to help make the abstractness of it all more concrete. Inevitably, I'd be in the middle of explaining my grandiose vision of creating software that would help provide a sophisticated analytic framework for understanding large quantities of relational data, and the person would interrupt: "Hey, the word 'utilitarian' looks like a rocket ship! Can we put in my name and see what comes out?" It was always the same story -- Rocket Ship 10, Analytical Framework, 0.

Finally, after a chemist friend enthusiastically noted "Wow, 'Austria' looks sort of like benzene," I decided that maybe there was more to the word diagrams than just their value as test data. Since people -- including non-chemists -- enjoyed trying to work out the word from the network of letters in the diagram, I thought it would make a good game. I added a clue, and voila, dfsWord was born.

## Historical note

This game is a reimplementation of a game I made a long time ago --  in 2003 -- called "RouteWord". It had gotten farily popular, with a mailing list of a few thousand people getting the daily puzzle.  (Back then, that was a lot).  Sadly, I got injured and had to stop working on it, and while I was recovering the domain lapsed and I lost the mailing list. 

So, I kind of just let it die, but I've always regretted it.  This year, my wife noticed the domain was available again, and she grabbed it for me as a gift.  So, this seemed like a good chance to bring it back.  I've changed it a bit so that now the onitereactve layout is part of the game (it's fun to arrange the nodes, IMO), and I've gone back through the Internet Archive to recreate the puzzles as I could.  I've also given attribution where I can to the original authors of the puzzles.  Getting and managing people's submissions was one of my favortite parts of the original game.

However, 20 years is a long time, and in the intereim someone else started using the name "RouteWord" as their word game.  So, rather than fight it, I just decided to rename it "dfsWord".  It's a little less catchy, but it's still a good name, especially for people who will recognize the initials as "depth first search." . Even though it's not a true DFS, it hints at the idea of graph traversal and a path.

I hope you enjoy it.

## Some historical links

* [Original O'Reilly Network article about RouteWord](https://web.archive.org/web/20040202104827/http://www.onlamp.com/pub/a/onlamp/2003/11/26/routewords.html).  This was one of the first things I did with O'Reilly after I finished my two books with them.

* [The original RouteWord site and design](https://web.archive.org/web/20040404110855/http://www.routeword.com/games/current_archive.html). 3d buttons, Tomcat, and JSP, all running on a Gateway server I maintained under my desk.  Those were the days.

* [The redesigned site using Blogger](https://web.archive.org/web/20180330175842/http://www.routeword.com/).  Blogging used to be cool, and Blogger was the vanguard.  Now it's on the dustbin of history, along with LiveJournal, Friendster, MySpace, and RSS.

* [Fun layouts page](https://web.archive.org/web/20161102155118/http://www.routeword.com/2004_08_08_.html).  People found it fun to just make cool layouts, so I made a page just for that and decided to carry it over to the new site.

`);
