// Common functions used across domains

// Construct the attribution string
export function getAttribution(submittedBy, submittedLocation) {
  var out = "";
  if (submittedBy) {
    out = "Submitted by " + submittedBy;
  }
  if (submittedLocation) {
    out = out + " from " + submittedLocation;
  }
  return out;
}

const RF = 1200; // Repulsive force of the spings.  Maybe this could be a slider?

// see https://gist.github.com/Kashkovsky/093fc4174cf52fccf81477a9bbf5ecd1
export function dataUrItoBlob(dataUri) {
  var binary = window.atob(dataUri.split(",")[1]);
  var mimeString = dataUri.split(",")[0].split(":")[1].split(";")[0];
  var array = [];
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: mimeString });
}

// Generate the list of nodes (i.e., unique letters) from a word
export function getNodes(w) {
  let retVal = [];
  w = w.toUpperCase();
  for (var i = 0; i < w.length; i++) {
    if (!retVal.includes(w[i])) {
      retVal.push(w[i]);
    }
  }
  return retVal;
}

// Return the common letter pairs independent of order
// Returns an array of arrays of edge pairs, excluding loops.
// For example, for "hello", it returns:
// [ ["H","E"], ["E,L"], ["L","O"]]
export function getEdges(w) {
  w = w.toUpperCase();
  var out = [];
  var used = [];
  for (var i = 0; i < w.length - 1; i++) {
    var p1 = w[i];
    var p2 = w[i + 1];
    var p1p2Used = used.indexOf(p1 + p2) > 0 ? true : false;
    var p2p1Used = used.indexOf(p2 + p1) > 0 ? true : false;
    if (!(p1p2Used || p2p1Used) && p1 != p2) {
      out.push([p1, p2]);
    }
    used.push(p1 + p2);
    used.push(p2 + p1);
  }
  return out;
}

// compute the distance b/t 2 points
export function distance(p1, p2) {
  var dsq = (p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2;
  return dsq > 0.0 ? Math.sqrt(dsq) : 0.0001;
}

// compute ta unit vector between 2 points
export function unitVector(p1, p2) {
  var d = distance(p1, p2);
  return {
    dx: (p2.x - p1.x) / d,
    dy: (p2.y - p1.y) / d,
  };
}

// Ensure that a given X point is within the canvas
export function xBound(x, canvas) {
  if (x < canvas.R) x = canvas.R + 2;
  if (x > canvas.W - canvas.R) x = canvas.W - canvas.R - 2;
  return x;
}

// Ensure that a given Y point is within the canvas
export function yBound(y, canvas) {
  if (y < canvas.R) y = canvas.R + 2;
  if (y > canvas.H - canvas.R) y = canvas.H - canvas.R - 2;
  return y;
}

//Return randomly placed nodes for a word
export function inializeNodes(word, canvas) {
  var nodes = {};
  getNodes(word).forEach((n) => {
    nodes[n] = {
      x: (canvas.W - 2 * canvas.R) * Math.random() + canvas.R,
      y: (canvas.H - 2 * canvas.R) * Math.random() + canvas.R,
    };
  });
  return nodes;
}

// Returns new node positions based on a force directed graph layout.
export function updateNodes(nodes, edges, canvas, SL) {
  // The forces we'll compute
  var fWalls = {}; // force exerted by the walls -- centers everything
  var fNodes = {}; // forces exerted by the nodes -- pushes nodes apart
  var fEdges = {}; // force exerted by the springs -- pulls nodes together

  // Ensure that each force component has a zero entry for each node
  Object.keys(nodes).forEach((k) => {
    fWalls[k] = { dx: 0.0, dy: 0.0 };
    fNodes[k] = { dx: 0.0, dy: 0.0 };
    fEdges[k] = { dx: 0.0, dy: 0.0 };
  });

  // Compute fWalls -- forces from the 4 walls of the container
  // Assume each exerts a repulsive force inwards
  // This centers the graph evenly within the canvas
  Object.keys(nodes).map((k) => {
    var N = unitVector({ x: nodes[k].x, y: 0 }, nodes[k]);
    N.dy *= RF / nodes[k].y ** 2;

    var S = unitVector({ x: nodes[k].x, y: canvas.H }, nodes[k]);
    S.dy *= RF / (canvas.H - nodes[k].y) ** 2;

    var W = unitVector({ x: 0, y: nodes[k].y }, nodes[k]);
    W.dx *= RF / nodes[k].x ** 2;

    var E = unitVector({ x: canvas.W, y: nodes[k].y }, nodes[k]);
    E.dx *= RF / (canvas.W - nodes[k].x) ** 2;

    //Add up the repusive forces
    fWalls[k] = {
      dx: N.dx + S.dx + E.dx + W.dx,
      dy: N.dy + S.dy + E.dy + W.dy,
    };
  });

  // Compute fNodes -- repulsive forces among the nodes
  Object.keys(nodes).map((k) => {
    var dx = 0.0;
    var dy = 0.0;
    Object.keys(nodes).map((j) => {
      if (k != j) {
        var v = unitVector(nodes[k], nodes[j]);
        var d = distance(nodes[k], nodes[j]);
        dx -= v.dx * (RF / d ** 2);
        dy -= v.dy * (RF / d ** 2);
      }
    });
    fNodes[k] = { dx: dx, dy: dy };
  });

  // Compute fEdges -- attractive forces between nodes connected by edges
  edges.forEach((e) => {
    var af = 0.1;
    var v = unitVector(nodes[e[0]], nodes[e[1]]);
    var d = distance(nodes[e[0]], nodes[e[1]]);
    // inward attraction from e1 to e2
    fEdges[e[0]] = {
      dx: fEdges[e[0]].dx + v.dx * af * (d - SL) * 0.5,
      dy: fEdges[e[0]].dy + v.dy * af * (d - SL) * 0.5,
    };
    // inward attraction from e2 to e1
    fEdges[e[1]] = {
      dx: fEdges[e[1]].dx - v.dx * af * (d - SL) * 0.5,
      dy: fEdges[e[1]].dy - v.dy * af * (d - SL) * 0.5,
    };
  });

  // Add all the forces acting on a node together and update its position
  // Also, check to make sure it stays within the boundary of the canvas
  var newNodes = {};
  Object.keys(nodes).map((k) => {
    var newX = nodes[k].x + fNodes[k].dx + fWalls[k].dx + fEdges[k].dx;
    var newY = nodes[k].y + fNodes[k].dy + fWalls[k].dy + fEdges[k].dy;
    // Update the positions of the node
    newNodes[k] = {
      x: xBound(newX, canvas),
      y: yBound(newY, canvas),
    };
  });

  return newNodes;
}
