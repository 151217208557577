import { marked } from "marked";

export const TEXT = marked(`
# Submit a Puzzle

Got an idea for a puzzle?  Submit it here!  I'll review it and publish the ones I think are a good match for the site.

## Guidelines

An ideal puzzle takes between 30 seconds to two minutes to solve, and is a sort of Zen koan: the clue poses an intriguing challenge, and the solution, when discovered, elicits an "Aha!" of sudden conection.

As a general rule, I'm particularly fond of puzzles that use humor, wordplay, double meanings, and the super geeky "meta-" submission (i.e. having a clue about the clue). 

### In general:

- You should not be able to guess the solution from only the clue (i.e. you should have to study the puzzle for a few minutes).
- Use puns, plays on words, riddles, etc.
- Don't be _too_ obscure or esoteric.
- Avoid highly technical jargon.
- Don't be in poor taste. (I'm not a prude, but I'm not going to publish anything that's offensive or gross.)
- Avoid political or religious topics.  Not that this is unimportant, but just do that somewhere else. 


### The Word

- Have at least one repeating letter in the solution word.
- Use only one word.
- Use a maximum of 20 _unique_ characters.

### The Clue

- The clue should pose a question that can be answered by who, what, or where.
- Try to incorporate a poetic construction, such as rhyme, rhythm, alliteration, assonance, consonance, tintinabulation, iambic pentameter, or one of those other things from Lit 101.
- Use a maximum of 25 characters (including spaces).


`);
