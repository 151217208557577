import { useEffect, useState } from "react";

import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";

import Chrome from "../Chrome";

import LayoutAndSolve from "../LayoutAndSolve";

import { TEXT } from "./text";
import "./index.css";

const textInputSX = {
  marginBottom: "12px",
  width: "100%",
};

const Main = (props) => {
  const [word, setWord] = useState("playground");

  const [puzzles, setPuzzles] = useState([
    {
      clue: "Ursa major (or a kite)",
      word: "hulahoops",
      submittedBy: "Ryan (MA)",
    },
    {
      clue: "3-D Hexagon",
      word: "abcdefa1ab2bc3cd4de5ef6123456",
      submittedBy: "Andrew",
    },
    {
      clue: "Snowflake",
      word: "abcdefa1ab2bc3cd4de5ef6",
      submittedBy: null,
    },
    {
      clue: "Cube",
      word: "abcdaefghefbcghd",
      submittedBy: null,
    },
    {
      clue: "Klingon Bird of Prey",
      word: "abceghfdbchdgdcg",
      submittedBy: null,
    },
    {
      clue: "6 pointed star",
      word: "abcdefghijklabdefhijlabcdfghjklbc",
      submittedBy: "Sherri",
    },
    {
      clue: "Optimized 6 pointed star",
      word: "abcdefghijklalbdfhjl",
      submittedBy: "Jeremy",
    },
    {
      clue: "Spider Web",
      word: "a1anonab1bopobc1cpqpcd1dqrqde1ersref1fstsfg1gtutgh1hunuha",
      submittedBy: "Wiggy, MA",
    },
    {
      clue: "Bow Tie",
      word: "abcdefgheijhekabeh",
      submittedBy: "Dan W., Ohio",
    },
  ]);

  const listPuzzles = (item) => {
    // Create the primary text, which is the clue and the submitter (if any)
    var primary = item.clue;
    if (item.submittedBy) {
      primary += " by " + item.submittedBy;
    }
    return (
      <ListItem
        style={{ backgroundColor: item.word == word ? "lightgray" : "white" }}
      >
        <ListItemText
          primary={primary}
          secondary={item.word}
          onClick={() => {
            setWord(item.word);
          }}
        />
      </ListItem>
    );
  };

  return (
    <Chrome>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={6}>
          <TextField
            sx={textInputSX}
            variant="outlined"
            label="Word"
            value={word}
            onChange={(e) => {
              setWord(e.target.value);
            }}
          />
          <LayoutAndSolve
            word={word}
            clue={""}
            submittedBy={""}
            submittedLocation={""}
            userImage={""}
            scale={1.1}
            hideClue={true}
            canvas={{ W: 350, H: 300, R: 15 }}
            onLayoutComplete={(img) => {
              //console.log(img);
            }}
            onSuccess={() => {
              console.log("You solved it!");
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography>
            <div dangerouslySetInnerHTML={{ __html: TEXT }} />
          </Typography>
          <div className="playgroundList">
            <List>
              {puzzles.map((item, i) => {
                return listPuzzles(item);
              })}
            </List>
          </div>
        </Grid>
      </Grid>
    </Chrome>
  );
};

export default Main;

/*

*/
