import { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { db } from "../../firebase";

import "./index.css";

import LayoutAndSolve from "../LayoutAndSolve";

const textInputSX = {
  marginRight: "12px",
  marginBottom: "12px",
};

const App = (props) => {
  const [word, setWord] = useState("");
  const [clue, setClue] = useState("");
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");

  return (
    <div className="anonymousSuggestion">
      <Typography variant="h5" component="h1" sx={{ marginBottom: "12px" }}>
        Suggest a puzzle
      </Typography>
      <Typography variant="body2" component="p" sx={{ marginBottom: "20px" }}>
        Reviewing suggestions is the best part of this for me, so please submit
        any ideas. You can use your name/alias or location if you want, but it's
        not required. Got comments or suggestions? Email me at
        andrew@odewahn.com.
      </Typography>
      <TextField
        sx={textInputSX}
        variant="outlined"
        label="Word"
        value={word}
        onChange={(e) => {
          setWord(e.target.value);
        }}
      />
      <TextField
        sx={textInputSX}
        variant="outlined"
        label="Clue"
        value={clue}
        onChange={(e) => {
          setClue(e.target.value);
        }}
      />
      <TextField
        sx={textInputSX}
        variant="outlined"
        label="Your name"
        value={name}
        onChange={(e) => {
          setName(e.target.value);
        }}
      />
      <TextField
        sx={textInputSX}
        variant="outlined"
        label="Your location"
        value={location}
        onChange={(e) => {
          setLocation(e.target.value);
        }}
      />
      <LayoutAndSolve
        word={word}
        clue={clue}
        scale={1.0}
        canvas={{ W: 300, H: 300, R: 15 }}
        submittedBy={name}
        submittedLocation={location}
        hideClue={true}
        onSuccess={() => {
          alert("You solved it!");
        }}
      />

      <Button
        variant="contained"
        onClick={() => {
          // Status can be one of: [private, submitted, accepted, reviewed]
          var docRef = db.collection("anonymousSuggestion").doc();
          docRef.set({
            puzzleId: docRef.id,
            word: word,
            clue: clue,
            submittedBy: name,
            submittedLocation: location,
            status: "private",
            createdAt: Date.now(),
          });
          // Clear the form
          setWord("");
          setClue("");
          setName("");
          setLocation("");
        }}
      >
        Submit
      </Button>
    </div>
  );
};

export default App;
